.praca {
  margin-top: 5vh;
}
.praca_image {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 30vh;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/almont-d855e.appspot.com/o/assets%2FWhatsApp%20Image%202022-09-26%20at%2019.47.00.jpeg?alt=media&token=be587840-b08d-42f9-b428-c71ea1db9719);
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  -webkit-transition: background-size 0.5s;
  transition: background-size 0.5s;
}
.praca_image > p {
  font-size: 1.2rem;
}
.praca_image1:hover {
  background-size: 103%;
}
.praca_image > h1 {
  margin-top: 3vh;
}
.praca_heading {
  font-size: 2.2rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
}
.praca_links {
  min-height: 70vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
}
.praca_links > li > a {
  font-size: 1.6rem;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 300;
  cursor: pointer;
  list-style: circle;
}
