.projektszczegoly {
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
}
.projektszczegoly_details {
  width: 100vw;
  height: 95vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.projektszczegoly_details > section {
  width: 50vw;
  height: 95vh;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.projektszczegoly_details > section > a > img {
  height: 100%;
}
.projektszczegoly_details > section > a {
  width: 100vw;
  height: 95vh;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.projektszczegoly_details > section > p {
  width: 80%;
  text-align: center;
}
.projektszczegoly_details_arrow {
  position: fixed;
  left: 0;
  top: 10vh;
  z-index: 5;
  font-size: 4rem;
  color: white;
  margin: 5px;
}
@media only screen and (max-width: 700px) {
  .projektszczegoly_details {
    flex-direction: column;
  }
  .projektszczegoly_details > section {
    width: 100vw;
  }
  .projektszczegoly_details > section > a > img {
    height: auto;
  }
}
