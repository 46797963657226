.praca {
  margin-top: 5vh;
}
.praca_image1 {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 30vh;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/almont-d855e.appspot.com/o/assets%2FWhatsApp%20Image%202022-09-26%20at%2019.47.00.jpeg?alt=media&token=be587840-b08d-42f9-b428-c71ea1db9719);
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  -webkit-transition: background-size 0.5s;
  transition: background-size 0.5s;
}
.praca_image1:hover {
  background-size: 103%;
}
.praca_image1 > h1 {
  margin-top: 3vh;
}
.praca_section1 {
  display: flex;
  flex-direction: column;
  gap: 3vh;
}
.praca_section1_heading {
  height: 20vh;
  font-size: 2.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  text-transform: uppercase;
}
.praca_image1 > p {
  font-size: 1.2rem;
}

/* praca section 2  */

.praca_section2 {
  display: flex;
  flex-wrap: wrap;
  min-height: 50vh;
  margin-bottom: 5vh;
}

/* praca section 2 container  */

.praca_section2_containers {
  width: 25%;
  display: flex;
  flex-direction: column;
}

/* praca section 2 zagniezdzenie */

.praca_section2_containers2 {
  padding: 0 4vh;
}
.praca_section2_containers2 > h3 {
  text-transform: uppercase;
  margin-bottom: 2vh;
  font-size: 1.3rem;
}
.praca_section2_containers2 > ul > li {
  margin: 1vh 0;
}
.praca_section2_containers2_dott {
  margin-right: 3px;
}

/* media  */

@media only screen and (max-width: 900px) {
  .praca_section2_containers {
    margin-bottom: 3vh;
    width: 50%;
  }
}

@media only screen and (max-width: 500px) {
  .praca_section2_containers {
    width: 100%;
    margin-bottom: 3vh;
  }
  .praca_section1_mainp {
    text-align: center;
  }
}
