@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

*{
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    overflow-x: hidden;
}
a{
    text-decoration: none;
}
img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }