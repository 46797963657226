.footer {
  background-color: #323232;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: Silver;
}
.footer > div {
  padding: 10px;
}
