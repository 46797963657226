.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  height: 10vh;
  position: fixed;
  width: 100vw;
  overflow-y: hidden;
  z-index: 5;
}
.logo {
  height: 50px !important;
}
.navbar_list > ul > li > a {
  color: black;
  text-transform: uppercase;
  font-size: 0.9rem;
}
/* a po najechaniu */
a.navbar_a {
  position: relative;
}
a.navbar_a:before {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  bottom: 0;
  right: 0;
  background-color: black;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
}
a.navbar_a:hover:before {
  visibility: visible;
  width: 100%;
}
/* koniec */
.navbar_list ul > li {
  border-right: 0.7px solid #dddddd;
  padding: 0 20px 0 0;
}
.navbar_list ul > li:last-of-type {
  border-right: none;
}
.navbar_list > ul {
  list-style-type: none;
  display: flex;
  gap: 1rem;
}
.navbar_logo > a > img {
  height: 140px;
  cursor: pointer;
}
.h1_navbar_heading {
  color: black;
}
.navbar_button {
  display: none;
}

.navbar_language {
  display: flex;
  font-size: 0.9rem;
  gap: 10px;
  height: 100%;
}

.navbar_language > button {
  background: none;
  border: none;
  cursor: pointer;
}

.lang_active {
  border-bottom: 1px solid black !important;
}

@media screen and (max-width: 768px) {
  .navbar_button {
    display: inline;
  }
  .navbar_list > ul {
    position: fixed;
    top: 10vh;
    right: 0;
    width: 50%;
    height: 90vh;
    flex-direction: column;
    background-color: white;
    justify-content: center;
    align-items: center;
    transform: translateX(100%);
    transition: 0.5s ease-in;
  }
  .navbar_list > ul > li > a {
    font-size: 1rem;
  }
  .navbar_list ul > li {
    border: none;
  }
}

.navbar_active {
  transform: translateX(0%) !important;
}
