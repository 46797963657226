.heading {
  background-size: 120%;
  background-repeat: no-repeat;
  -webkit-transition: background-size 0.5s;
  transition: background-size 0.5s;
  background-position: center;
  width: 100vw;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.heading > h2 {
  color: white;
  font-size: 2rem;
  width: 200px;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.278);
  display: flex;
  justify-content: center;
}
.heading:hover {
  background-size: 135%;
}
@media only screen and (max-width: 1200px) {
  .heading {
    height: 100vh;
    background-size: cover;
  }
  .heading:hover {
    background-size: cover;
  }
}
