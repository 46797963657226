.o-nas {
  margin-top: 5vh;
}
.o-nas_image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 30vh;
  background-image: url(https://picsum.photos/1920/1080);
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  -webkit-transition: background-size 0.5s;
  transition: background-size 0.5s;
}
.o-nas_section2_box {
  width: 20vw;
  height: 30vh;
  /* background-color: pink; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.o-nas_section2_container {
  display: flex;
  justify-content: space-around;
  height: 75%;
  align-items: center;
}
.o-nas_section2 {
  display: flex;
  flex-direction: column;
  height: 60vh;
}
.o-nas_section2_heading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25%;
}
.o-nas_section2_box > h4 {
  display: block;
  margin-top: 2vh;
}
.o-nas_section2_box > p {
  display: block;
  margin-top: 1vh;
  text-align: center;
}
.o-nas_section2_heading > h3 {
  font-size: 1.5rem;
}


