.posts {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.posts_heading {
  margin: 20px;
}
.posts_notfound {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}
.post_container {
  width: 90vw;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 10px;
  align-items: center;
  justify-items: center;
}
.post_item {
  width: 400px;
  height: 400px;
  background-color: #dbdbdb;
  border-radius: 5px;
}
.posts > button {
  margin: 10px;
  background-color: #dbdbdb;
  padding: 10px;
}
.posts > button:hover {
  cursor: pointer;
}
