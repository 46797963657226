.oferta {
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
}
.oferta_sections {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.oferta_sections > section {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
}
.oferta_sections > section > img {
  width: 50%;
}
.oferta_section_content {
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.oferta_section_content_container {
  width: 25vw;
}
.oferta_section_content_top {
  margin-bottom: 5%;
  font-size: 2.3rem;
  font-weight: 500;
}
.oferta_section_content_bottom {
  font-size: 1.2rem;
  font-weight: 100;
}

@media only screen and (max-width: 1050px) {
  .oferta_sections > section:nth-of-type(1) {
    flex-direction: column-reverse;
  }
  .oferta_sections > section {
    flex-direction: column;
  }
  .oferta_section_content {
    width: 100vw;
    height: 100vh;
  }
  .oferta_sections > section > img {
    width: 100vw;
    height: 100vh;
  }
  .oferta_section_content_container {
    width: 80vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .oferta_section_content_top > p {
    width: 80vw;
    text-align: start;
  }
}
