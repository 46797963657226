.section {
  display: flex;
  flex-direction: column;
}
.section_block {
  width: 50%;
  text-justify: newspaper;
  padding: 0 7vw;
  display: flex;
  text-align: left;
}

.section_content {
  display: flex;
  margin-bottom: 10vh;
}
.section_heading {
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 400;
}
.section_p {
  font-size: 1.2rem;
  font-weight: 100;
}

@media only screen and (max-width: 1150px) {
  .section_block {
    width: 100%;
    text-justify: newspaper;
    padding: 0 7vw;
    display: flex;
  }
  .section_content {
    flex-wrap: wrap;
    display: flex;
    margin-bottom: 5vh;
  }
  .section_heading {
    height: 15vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    font-weight: 400;
  }
}

@media only screen and (max-width:850px) {
  .section_heading {
    height: 15vh;
    display: flex;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
  }
}
